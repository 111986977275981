import { Tooltip } from 'antd';
import Icons from 'components/icons';


const UserPhoneField = ({ user }) => {

    const { sendPassword } = user && user;

    return (

        <div className='phone-field-box'>
            <span className='col'>{ user.phone }</span>
            <span className='col'>
                <Tooltip overlayClassName="model-tooltip" destroyTooltipOnHide={ true } title={ sendPassword?.message }>
                    <span className='icon'>
                        { sendPassword?.created_at && <Icons.Key /> }
                    </span>
                </Tooltip>
            </span>
        </div>
    );
};

export default UserPhoneField;