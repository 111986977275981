import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Pages } from "components/pages";

export default function ExhibitionsRoute() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <Pages.Exhibitions />
      </Route>

      <Route path={`${path}/:id`}>
        {({ match, history }) => (
          <Pages.Exhibition match={match} history={history} exact />
        )}
      </Route>
      <Route path={`${path}/create`} exact>
        <Pages.Exhibition />
      </Route>
    </Switch>
  );
}
