

function convertToSelect(data) {

    const result = [];

    data?.forEach(item => {
        result.push({
            label: `${ item.node.name } ${ item.node.surname }`,
            value: item.node.id,
            disabled: (item.node.role_id === '1' || item.node.role_id === '2'),
        });
    });
    return result;
}



const usersFuncHelper = {
    convertToSelect,
};

export default usersFuncHelper;